import React from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import { Button } from 'antd';

const colorPath = [
  {
  "source": 0,
  "target": 1
  },  
  {
  "source": 1,
  "target": 2
  },
  {
  "source": 2,
  "target": 3
  },
  {
  "source": 3,
  "target": 4
  },
  {
  "source": 4,
  "target": 5
  },
  {
  "source": 5,
  "target": 6
  },
  {
  "source": 6,
  "target": 7
  },
  {
  "source": 7,
  "target": 8
  },
]

export default class BeamSearchViz extends React.Component {
  state = {
    timerActive: false,
    stagedColorPath: []  
  }
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     timerActive: false,
  //     stagedColorPath: []
  //   }
  // }

  resize = () => this.drawTree();

  componentDidMount() {
    this.loadData();
    window.addEventListener('resize', this.resize);
  }

  loadData() {
    axios.get("https://gist.githubusercontent.com/katnoria/874f0eaa171070f4a6ade957470a3d47/raw/4330297667e1b542cff4d4570c5f558a9ec3eaa5/wordtree_k3_001.json")
      .then(response => {
        let dataset = response.data;
        this.setState({ dataset });
        this.drawTree();
      });
  }

  startPathTrace = () => {
    if (!this.activeTimer) {
      let timerActive = true;
      this.setState({ timerActive });
      this.activeTimer = setInterval(() => this.updateStagedColorPath(), 1000);
    }
  }

  stopPathTrace = () => {
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
      let timerActive = false;
      this.setState({ timerActive });
    }
  }

  handleAction = () => {
    console.log('handle action');
    let { timerActive } = this.state;
    if (timerActive) {
      this.stopPathTrace();
    } else {
      this.startPathTrace();
    }
  }

  extractCaption = (stagedColorPath, dataset) => {
    let result = stagedColorPath.reduce((acc, cur) => {
      // console.log(acc, cur)
      let item = acc['children'] ? acc['children'].filter(row => row.wid == cur.target) : null;
      if (item) {
        acc['children'] = item[0].children
        acc['caption'] = acc['caption'] + ' '  + item[0].word    
      }
      return acc
    }, {'children': dataset.children, 'caption': '<start>'});

    return result.caption
  }

  updateStagedColorPath = () => {
    let { stagedColorPath, caption, dataset  } = this.state;
    if (stagedColorPath) {
      if (stagedColorPath.length >= colorPath.length) {
        stagedColorPath = [colorPath[0]]
      } else {
        stagedColorPath.push(colorPath[stagedColorPath.length])
      }
      caption = this.extractCaption(stagedColorPath, dataset);

      this.setState({ stagedColorPath, caption });
      this.drawTree(this.state);
    }
  }


  componentWillUnmount() {
    this.stopPathTrace();
    this.drawTree();
  }

  drawTree() {
    draw(this.state);
  }

  render() {
    let { timerActive, caption } = this.state;
    // let activeIcon = timerActive ? 'pause' : 'caret-right';
    let activeButtonTitle = timerActive ? 'Pause Animation' : 'Play Animation';
    return (
      <div>
        <div className="gen-caption">
          {caption}
        </div>
        <div>
          <Button onClick={this.handleAction.bind(this)}>{activeButtonTitle}</Button>
          {/* <span style={{marginLeft: 16}}>Click on the button to run the visualisation </span> */}
        </div>
        <div className="beamsearch-viz"></div>
      </div>
    )
  }
}

const tree = (data, width, height) => {
    const root = d3.hierarchy(data);
    // root.dx = 20;
    root.dx = Math.min(height * 0.025, 25);
    console.log(`height: ${height}, ${height * 0.025}, root.dx: ${root.dx}`);
    root.dy = width / (root.height + 1);
    return d3.tree().nodeSize([root.dx, root.dy])(root);
  }


const draw = (props) => {
  if(!props.dataset) {
    return;
  }

    d3.select('.beamsearch-viz > *').remove(); 
    const data = props.dataset;
    const stagedColorPath = props.stagedColorPath;
    const width = document ? 
        Math.min(Math.max(document.documentElement.clientWidth, window.innerWidth || 0), 1200)
        : 1200;
        
    let height = 1000;

    const aspect = width / height;
    const zoom = (props.maxDepth && parseInt(props.maxDepth) > 3) ? 1.5 : 1.;
    const svgHeight = 400 //height*zoom;
    console.log(props);
    console.log(`width: ${width}, height: ${height}, aspect: ${aspect}, zoom: ${zoom}, svgHeight: ${svgHeight}`);
    const svg = d3.select('.beamsearch-viz').append('svg')
        .attr('viewBox', `-20 0 ${width} ${svgHeight}`)
        .attr('preserveAspectRatio', 'xMinYMid')
        // .attr('width', "100%")
        .attr('height', "100%")
        .attr('id', 'svg-beamsearch-viz')

    const root = tree(data, width, height);

    let x0 = height/2;
    let x1 = -x0;
    root.each(d => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });
  
      // const svg = d3.select(DOM.svg(width, x1 - x0 + root.dx * 2))
      //   .style("width", "100%")
      //   .style("height", "auto");
  
      // const svg = d3.create("svg")
      //   .attr("viewBox", [0, 0, width, x1 - x0 + root.dx * 2]);
  
    // const svg = d3.select(DOM.svg(width, height))
    // .style('overflow', 'visible')
    // .attr("viewBox", "-20 0 1100 200")  
    
    const g = svg.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 12)
        .attr("transform", `translate(${root.dy / 3},${root.dx - x0})`);
        
    const link = g.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-width", 1.5)
    .selectAll("path")
      .data(root.links())
      .enter().append("path")
      // .transition(t)  
      .attr("stroke-width", d => {
          let found = stagedColorPath.filter(item => item.source === d.source.data.wid && item.target === d.target.data.wid)
          return found.length > 0 ? 2.5 : 1.5              
        })  
        .attr("stroke", d => {
          let found = stagedColorPath.filter(item => item.source === d.source.data.wid && item.target === d.target.data.wid)
          return found.length > 0 ? 'orange' : '#555'        
        })
      .attr("stroke-opacity", d => {
          let found = stagedColorPath.filter(item => item.source === d.source.data.wid && item.target === d.target.data.wid)
          return found.length > 0 ? 0.9 : 0.2 
      })  
        .attr("d", d3.linkHorizontal()
            .x(d => d.y)
            .y(d => d.x));
    
    const node = g.append("g")
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
      .selectAll("g")
      .data(root.descendants().reverse())
      .enter().append("g")
        .attr("transform", d => `translate(${d.y},${d.x})`);
  
    node.append("circle")
        .attr("fill", d => d.children ? "#555" : "#999")
        .attr("r", 2.5);
  
    node.append("text")
        .attr("fill", d => {
          if (d.data.word === '<start>') {
            return "green"
          } else if (d.data.word === '<stop>') {
            return 'red'
          } else {
            return 'black'
          }
        })  
        .attr("dy", "0.2em")
        .attr("x", d => d.children ? -6 : -16)
        .attr("y", d => d.children ? 0 : -6)  
        .attr("text-anchor", d => d.children ? "end" : "start")
        .text(d => d.data.word)
      .clone(true).lower()
        .attr("stroke", "white");
    
      node.append("text")
        .attr("font-size", 8)
        .attr("fill","grey")
        .attr("dy", "0.6em")
        .attr("x", d => d.children ? -6 : -16)
        .attr("y", d => d.children ? -12 : 6)    
        .attr("text-anchor", d => d.children ? "end" : "start")
        .text(d => ["<start>","<stop>"].includes(d.data.word) ? "" : `(${d.data.prob})`)
        // .text(d => d.data.children.length > 0 ? `(${d.data.temp})` : ``)  
      .clone(true).lower()
        .attr("stroke", "white");    
        
}