import React from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import { Button } from 'antd';

export default class PureSamplingSearchViz extends React.Component {
  state = {
    timerActive: false,
    currentIndex: 0,
    caption: ''
  }

  resize = () => this.drawChart();

  componentDidMount() {
    this.loadData();
    window.addEventListener('resize', this.resize);
  }

  loadData() {
    axios.get(this.props.url)
      .then(response => {
        let dataset = response.data;
        let { caption, currentIndex} = this.state;
        if (currentIndex >= 0) {
          caption = `${dataset[currentIndex].selected_word}`
        }
        this.setState({ dataset, caption });
        this.drawChart();
      });
  }

  startPathTrace = () => {
    if (!this.activeTimer) {
        this.setState({
            timerActive: true
          });    
      this.activeTimer = setInterval(() => this.updateActiveDataset(), 1000);
    }
  }

  stopPathTrace = () => {
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
      this.activeTimer = null;
      this.setState({
        timerActive: false
      });
    }
  }

  handleAction = () => {
    if (this.activeTimer) {
      this.stopPathTrace();
    } else {
      this.startPathTrace();
    }
  }

  updateActiveDataset = () => {
    let { currentIndex, dataset, caption } = this.state;    

    let words_len = Object.keys(dataset).length -1 ;
    if (currentIndex >= words_len) {
      currentIndex = 0
      caption = `${dataset[currentIndex].selected_word}`
    } else {
      currentIndex += 1
      caption = `${caption} ${dataset[currentIndex].selected_word}`    
    }
    this.setState({ currentIndex, caption });
    this.drawChart(this.state);    
  }


  componentWillUnmount() {
    this.stopPathTrace();
    this.drawChart();
  }

  drawChart() {    
    draw(this.props.chartname, this.state, this.props.topk, this.props.topk_color);
  }

  render() {
    let { timerActive, caption } = this.state;
    // let activeIcon = timerActive ? 'pause' : 'caret-right';
    let activeButtonTitle = timerActive ? 'Pause Animation' : 'Play Animation';
    return (
      <div>
        <div className="gen-caption">
          {caption}
        </div>
        <div>
          <Button onClick={this.handleAction.bind(this)}>{activeButtonTitle}</Button>
          {/* <span style={{marginLeft: 16}}>Click on the button to run the visualisation </span> */}
        </div>
        <div className={`${this.props.chartname}-viz`}></div>
      </div>
    )
  }
}


const draw = (chartname, props, topk, topk_color) => {
  if (!props.dataset) {
    return;
  }

  const index = props.currentIndex;
  if (index < 0) {
    return;
  }

  let max_items = 80;
  topk = topk ? parseInt(topk) : 0;
  topk_color = topk_color ? topk_color : 'steelblue';

  d3.select(`.${chartname}-viz > *`).remove();
  console.log(props);
  const data = props.dataset[index].data.slice(0, max_items);
  const selectedProb = props.dataset[index].selected_index;

  let margin = ({ top: 20, right: 0, bottom: 80, left: 40 })
  const width = document ?
    Math.min(Math.max(document.documentElement.clientWidth, window.innerWidth || 0), 1200)
    : 1200;

  let height = 400;

  const svgHeight = 400 //height*zoom;
  // console.log(`width: ${width}, height: ${height}, aspect: ${aspect}, zoom: ${zoom}, svgHeight: ${svgHeight}`);
  const svg = d3.select(`.${chartname}-viz`).append('svg')
    .attr('viewBox', `0 0 ${width} ${svgHeight}`)
    .attr('preserveAspectRatio', 'xMinYMid')
    // .attr('width', "100%")
    .attr('height', "100%")
    .attr('id', `svg-${chartname}-viz`);

  let x = d3.scaleBand()
    .domain(data.map(d => d.name))
    .range([margin.left, width - margin.right])
    .padding(0.5)

  let y = d3.scaleLinear()
    // .domain([0, d3.max(data, d => d.value)]).nice()
    .domain([0, 1.]).nice()
    .range([height - margin.bottom, margin.top]);

  let xAxis = g => g
    .attr("transform", `translate(0, ${height - margin.bottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));

  let yAxis = g => g
    .attr(`transform`, `translate(${margin.left}, 0)`)
    .call(d3.axisLeft(y))
    .call(g => g.select(".domain").remove());

  svg.append("g")
    .attr("fill", "steelblue")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", d => x(d.name))
    .attr("y", d => y(d.value))
    .attr("height", d => y(0) - y(d.value))
    .attr("width", x.bandwidth())
    // .attr("fill", d => d.index == selectedProb ? "orange" : d.index == maxProb ? "steelblue" : "lightgray");
    .attr("fill", (d, i) => d.index == selectedProb ? "orange" : i <= topk ? topk_color : "lightgray");

  svg.append("g")
    .call(xAxis)
    .selectAll('text')
    .attr("y", 0)
    .attr("x", 9)
    .attr("dy", ".35em")
    .attr("transform", "rotate(90)")
    .style("text-anchor", "start");
  ;

  svg.append("g")
    .call(yAxis);

}